<template>
    <div class="onboard-img-container">
        <div class="img-wrapper">
            <img class="img" :src="require('@/assets/images/onboard/onboard3.webp')" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnBoard3',
}
</script>

<style scoped lang="scss">
.img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.img {
    width: 90%;
}
</style>
